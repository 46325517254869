<template>
  <v-container fluid>
    <div class="container">
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            :items="schoolYearList"
            label="Chosissez  l'année scolaire"
            v-model="schoolYear"
            outlined
            chips
            dense
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <div v-if="schoolYear">
      <v-data-table
        class="pt-3"
        flat
        :loading="loading ? '#144881' : null"
        :headers="headers"
        :search="searchData"
        :items="Ues"
        :items-per-page="5"
        sort-by=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Unité d'enseignement</v-toolbar-title>

            <v-spacer></v-spacer>
            <template>
              <v-btn
                v-if="UserProfile.role == 'Administrateur'"
                color="#144881"
                dark
                class="mb-2 mr-8"
                @click="addItem"
                v-bind="attrs"
                outlined
                pill
                v-on="on"
                rounded
              >
                <v-icon left> mdi-account-outline </v-icon>
                Ajout
              </v-btn>
            </template>
          </v-toolbar>
          <v-container fluid>
            <v-expansion-panels flat style="border: 1px solid #144881">
              <v-expansion-panel class="elevation-0 pa-0">
                <v-expansion-panel-header>
                  Tri / Recherche ...
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12" md="12">
                    <v-text-field
                      color="#144881"
                      label="Recherche par  année scolaire"
                      class="pt-5 pa-2"
                      filled
                      prepend-inner-icon="mdi-magnify"
                      dense
                      v-model="searchData"
                      outlined
                      flat
                      background-color="grey lighten-4"
                      rounded
                    ></v-text-field>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu elevation="0" right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="mr-md-10">
                <v-icon color="success" size="30">mdi-forwardburger</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-tooltip bottom color="green">
                <template v-slot:activator="{ on, attrs }">
                  <router-link :to="'/ue/' + item._id">
                    <v-btn class="ml-3" icon>
                      <v-icon size="20" v-bind="attrs" v-on="on" color="green"
                        >mdi-arrow-right</v-icon
                      >
                    </v-btn>
                  </router-link>
                </template>
                <span>Voir les ECUE associés</span>
              </v-tooltip>
              <v-tooltip
                v-if="UserProfile.role == 'Administrateur'"
                bottom
                color="warning"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-3" icon>
                    <v-icon
                      size="20"
                      @click="updateItem(item)"
                      v-bind="attrs"
                      v-on="on"
                      color="warning"
                      >mdi-pencil-box-multiple-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip
                v-if="UserProfile.role == 'Administrateur'"
                bottom
                color="red"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ml-3" icon>
                    <v-icon
                      size="20"
                      @click="deleteItem(item)"
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <h3 class="text-center">S'il vous plait sélectioner une année</h3>
    </div>
    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        v-if="editorDialog"
        :fields="fields"
        :model="Model"
        :callback="callBack"
        @success="successEditor"
        :title="
          Model && Model._id
            ? `Modification d'une unité d'enseignement`
            : `Ajout d'une unité d'enseignement`
        "
        :actionBtnLabel="Model && Model._id ? `Modifier` : `Ajouter`"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "../../utils/data";
import { addUe, updateUe, deleteUe } from "../../api/user";
import { getLevels } from "@/api/universal";
import EditorX from "../../components/universal/EditorX.vue";
import DeleteDialog from "../../components/universal/DeleteDialog.vue";
import { functions } from "../../utils/constant.js";

export default {
  name: "Ues",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    schoolYearList: [],
    headers: [
    {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
      {
        text: "Nom",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Acronyme",
        sortable: true,
        value: "acronym",
      },
      {
        text: "Description",
  sortable: true,
        value: "description",
      },
      {
        text: "Mention",
        sortable: true,
        value: "departmentName",
      },
      {
        text: "Option",
        sortable: true,
        value: "optionName",
      },
      {
        text: "Dégré",
        sortable: true,
        value: "level",
      },
      {
        text: "Semestre",
        sortable: true,
        value: "semesterName",
      },
     
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
    schoolYear: "",
    schoolYearId: "",
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "Ues",
      "Options",
      "Semesters",
      "SchoolYears",
      "UserProfile",
    ]),
    fields() {
      return [
        {
          name: "name",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "acronym",
          label: "Acronyme",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "description",
          label: "Descripiton",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "level",
          label: "Dégré",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: getLevels(),
          },
        },
        {
          name: "option",
          label: "Option",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getOptions,
              params: { department: "" },
              getter: this.Options,
              fieldName: "name",
            },
          },
        },
        {
          name: "semester",
          label: "Semestre",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getSemesters,
              params: { schoolYear: this.schoolYearId || '' },
              getter: this.Semesters,
              fieldName: "name",
            },
          },
        },
      ];
    },
  },

  watch: {
    SchoolYears() {
      this.schoolYearList = this.SchoolYears.map((item) => item.name);
    },
    async schoolYear() {
      await this.getUes({
            schoolYear: functions.getItemId(this.SchoolYears, this.schoolYear),
          });


          this.schoolYearId = functions.getItemId(this.SchoolYears, this.schoolYear)
    },
  },
  methods: {
    ...mapActions(["getUes", "getOptions", "getSemesters", "getSchoolYears"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getSchoolYears();

        if(this.UserProfile && this.UserProfile.school.currentYear){
          this.schoolYear = this.UserProfile.school.currentYear.name
        }
        if (this.schoolYear) {

          await this.getUes({
            schoolYear: functions.getItemId(this.SchoolYears, this.schoolYear),
          });
        }

        
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les années")
        );
      }
      this.loading = false;
    },
    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addUe;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateUe;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteUe;
    },
  },
};
</script>

<style >
</style>
